import React from "react";
import InputTypes from "../input-types";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select'
import { calendarActionCreators } from "../../../../stores/lis-calendar-store";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CommentBox from './comment-box';
import renderHTML from 'react-render-html';
import { minutesActionCreators } from "../../../../stores/lis-minutes-store";
import { billActionCreators } from "../../../../stores/lis-legislation-store";
import ReactTooltip from "react-tooltip";
import moment from "moment";

const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});

class NonLegislativeForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedMotion: '',
            insertEntry: [{ label: "No Legislation" }],
            templateInputs: [],
            references: [],
            motionOptions: [],
            selectedMotions: [],
            newEntries: [],
            templateInputs: [],
            templatedEdited: false,
            showMenu: -1,
            showCommentBox: false,
            commentBoxContent: [],
            textList: [],
            editing: false,
            initialText: []
        }

        this.closeNewEntries = this.closeNewEntries.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.onEntryDragEnd = this.onEntryDragEnd.bind(this);
        this.onActivityDragEnd = this.onActivityDragEnd.bind(this);
        this.removeActivity = this.removeActivity.bind(this);
        this.addBackActivity = this.addBackActivity.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.insertMotion = this.insertMotion.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleMotionChange = this.handleMotionChange.bind(this);
        this.getCatActionList = this.getCatActionList.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.addEntry = this.addEntry.bind(this);
        this.handleCommentBoxChange = this.handleCommentBoxChange.bind(this);
        this.toggleCommentBox = this.toggleCommentBox.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
        this.getTextList = this.getTextList.bind(this);
        this.getNonLegislativeActionList = this.getNonLegislativeActionList.bind(this);
        this.handleNewMotionChange = this.handleNewMotionChange.bind(this);

        this.menuBoxRef = React.createRef();
    }

    closeNewEntries() {
        this.setState({
            newEntries: []
        });
    }

    toggleEdit(index) {
        let newEntries = [...this.state.newEntries];
        let init = [];
        if (this.state.newEntries[index]) {
            newEntries[index] = null;
        } else {
            if (this.state.newEntries.length === 0) {
                newEntries.length = this.props.formData.MinutesEntries.length;
            }
            //If the user has multiple edit entries open clicking the X on one doesn't kill the changes to another
            newEntries[index] = JSON.parse(JSON.stringify(this.props.formData.MinutesEntries[index]));
            // Test to see if there are zero minute activities. If so then create one.
            if (!newEntries[index].MinutesActivities || newEntries[index].MinutesActivities.length === 0) {
                newEntries[index].MinutesActivities = [];
                const newActivity = {
                    Description: newEntries[index].EntryText,
                    ActivityReferences: [],
                    InPreview: true
                };
                newEntries[index].MinutesActivities.push(newActivity);
            }
            //Test to see if the activities have zero activity references. If they have zero then add a blank text one
            newEntries[index].MinutesActivities.forEach(act => {
                if (act.EventCode) {
                    const eventReference = this.state.references.find(evt => evt.EventCode === act.EventCode && evt.IsPassed === act.IsPassed);
                    if (eventReference) {
                        const actionReferences = eventReference.ActionReferences;
                        if (actionReferences) {
                            const missingActivitiyReferences = actionReferences.filter(ref => !act.ActivityReferences || !act.ActivityReferences.map(existingActivities => existingActivities.ActionReferenceType).includes(ref.ActionReferenceType));
                            if (missingActivitiyReferences.length) {
                                act.ActivityReferences = act.ActivityReferences ? act.ActivityReferences.concat(JSON.parse(JSON.stringify(missingActivitiyReferences))) : JSON.parse(JSON.stringify(missingActivitiyReferences));
                            }
                        }
                    }
                }

                if (!act.ActivityReferences || act.ActivityReferences.length === 0) {
                    const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
                    if (textType) {
                        //Sometimes ActivityReferences is undefined. Make a blank one so the push does not fail
                        act.ActivityReferences = [];
                        act.ActivityReferences.push({
                            ActionReferenceTypeID: textType.ActionReferenceTypeID,
                            ActionReferenceType: textType.ActionReferenceType,
                            ReferenceText: act.Description
                        });
                    }
                }
                //Need something from act we can reference back to when displaying the initial activity text value as a label
                init.push({ ReferenceText: act.Description, MinutesActivityID: act.MinutesActivityID })
            });
        }
        this.setState({
            newEntries: newEntries,
            editing: !this.state.editing,
            initialText: init
        });
    }

    onActivityDragEnd(result, entryIndex) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        let entries = [...this.state.newEntries];
        let activities = entries[entryIndex].MinutesActivities;
        const [removed] = activities.splice(result.source.index, 1);
        activities.splice(result.destination.index, 0, removed);
        activities.forEach((element, index) => {
            element.Sequence = index + 1
        });
        this.setState({
            newEntries: entries
        });
    }

    onEntryDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        let entries = [...this.props.formData.MinutesEntries];
        const [removed] = entries.splice(result.source.index, 1);
        entries.splice(result.destination.index, 0, removed);
        entries.forEach((element, index) => {
            element.Sequence = index + 1;
        });
        this.props.onMultipleEntryChange(entries, this.props.currentActivitySequence);
        this.props.handleSaveEntry(entries);
        // 
        this.setState({
            newEntries: []
        });
    }

    removeActivity(entryIndex, activityIndex) {
        let entries = [...this.state.newEntries];
        entries[entryIndex].MinutesActivities[activityIndex].DeletionDate = new Date();
        this.setState({
            newEntries: entries
        });
    }

    addBackActivity(entryIndex, activityIndex) {
        let entries = [...this.state.newEntries];
        entries[entryIndex].MinutesActivities[activityIndex].DeletionDate = null;
        entries[entryIndex].MinutesActivities[activityIndex].IsPublic = false;
        this.setState({
            newEntries: entries
        });
    }

    handleChange(entryIndex, activityIndex, referenceIndex, reference, previousValue) {
        //Update the input with new text. Save change to this components state so it doesn't update the parent just yet (in case the user clicks the X)
        let entries = this.state.newEntries;
        let activity = entries[entryIndex].MinutesActivities[activityIndex];
        activity.ActivityReferences[referenceIndex] = reference;
        let desc = '';
        activity.ActivityReferences.forEach(tInput => desc += tInput.ReferenceText);
        activity.Description = desc;
        activity.IsPublic = false;
        entries[entryIndex].MinutesActivities[activityIndex] = activity;
        //if they are selecting a communication, add minutessummaries for each communicationsummary on that communication
        if (reference.ActionReferenceType === 'Communication') {
            //set the communication data for the entry level
            entries[entryIndex].CommunicationID = reference.ReferenceID;
            entries[entryIndex].CommunicationDate = reference.CommunicationDate;
            if (reference.CommunicationNumber) {
                entries[entryIndex].CommunicationNumber = Number(reference.CommunicationNumber);
            }
            //set minutessummaries array if not already available
            entries[entryIndex].MinutesSummaries = entries[entryIndex].MinutesSummaries || [];
            //if they are changing from one communication to another, delete the summaries related to the old communication
            if (previousValue && previousValue.ReferenceID) {
                entries[entryIndex].MinutesSummaries = entries[entryIndex].MinutesSummaries.filter(ms => ms.ModificationDate);
                entries[entryIndex].MinutesSummaries.filter(ms => ms.ReferenceID === previousValue.ReferenceID).forEach(ms => ms.DeletionDate = moment())
            }
            if (reference.CommunicationSummaries) {
                reference.CommunicationSummaries.filter(cs => cs.MinutesSummary && cs.CommunicationID).forEach(commSummary => {
                    entries[entryIndex].MinutesSummaries.push({ MinutesSummary: commSummary.MinutesSummary, ReferenceID: commSummary.CommunicationID })
                })
            }
        }
        this.setState({
            newEntries: entries
        });
    }

    saveChange(entryIndex) {
        let entry = this.state.newEntries[entryIndex];
        //Send the index of the minute entry, the entire minute entries object (so we don't have to save the entire book),
        //the value of the text input, and the sequence to show which category to update
        this.props.onEntryChange(entryIndex, entry, this.props.currentActivitySequence);
        this.props.handleSaveEntry(entry);
        //Turn it back into a span
        let newEntries = [...this.state.newEntries];
        newEntries[entryIndex] = null;
        this.setState({
            newEntries: newEntries,
            editing: false
        });
    }

    insertMotion(entryIndex) {
        //A new motion wants to be added so take all this data and sent it to the parent div to be added to the state
        const { templatedEdited } = this.state;
        let templateInputs = [...this.state.templateInputs];
        let selectedMotions = [...this.state.selectedMotions];
        const entries = [...this.props.formData.MinutesEntries];
        const selectedMotion = selectedMotions[entryIndex] ? selectedMotions[entryIndex] : this.state.selectedMotion;
        let entry = entryIndex >= 0 ? entries[entryIndex] : {};
        //If it is a null then create a new one so the push doesn't fail
        if (!entry.MinutesActivities) {
            entry.MinutesActivities = [];
        }
        let desc = selectedMotion.Description ? selectedMotion.Description : this.state.selectedMotion.label;
        //If the user has changed the value of the action references then use those for the description instead of the existing one.
        let activityRefs = [];
        if (templatedEdited) {
            desc = '';
            templateInputs[entryIndex].forEach((tInput, actIndex) => {
                desc += tInput.ReferenceText;
                activityRefs[actIndex] = {
                    ActionReferenceTypeID: tInput.ActionReferenceTypeID,
                    ActionReferenceType: tInput.ActionReferenceType,
                    Sequence: tInput.Sequence,
                    ReferenceText: tInput.ReferenceText,
                    ReferenceID: tInput.ReferenceID
                }
            });
        } else {
            const textType = this.props.actionTypes.find(type => type.ActionReferenceType === 'Text');
            if (textType) {
                activityRefs = [{
                    ActionReferenceTypeID: textType.ActionReferenceTypeID,
                    ActionReferenceType: textType.ActionReferenceType,
                    ReferenceText: desc
                }];
            }
        }
        entry.MinutesActivities.push(Object.assign(selectedMotion, {
            MinutesEntryID: entry.MinutesEntryID,
            ActivityReferences: activityRefs,
            Description: desc
        }));
        //User cannot add an activity to an entry while they are editing that entry
        if (this.state.newEntries[entryIndex]) {
            let newEntries = [...this.state.newEntries];
            newEntries[entryIndex] = null;
            this.setState({
                newEntries: newEntries
            })
        }
        this.props.onEntryChange(entryIndex, entry, this.props.currentActivitySequence);
        //Set MinutesCategoryID if we're adding an order of business entry and entryText
        if (!entry.MinutesCategoryID) {
            entry.MinutesCategoryID = this.props.formData.MinutesCategoryID;
            entry.EntryText = desc;
        }
        this.props.handleSaveEntry(entry);

        selectedMotions[entryIndex] = null;
        templateInputs[entryIndex] = null;
        this.setState({
            selectedMotions: selectedMotions,
            templateInputs: templateInputs
        });
    }

    handleTemplateChange(entryIndex, activityIndex, referenceIndex, reference) {
        let templateInputs = [...this.state.templateInputs];
        templateInputs[entryIndex][referenceIndex] = reference;
        //Used when adding the motion to know whether to use the existing description or concatenate together the action reference values
        //If they have change a value it is save to say they want the the description to be the action references stringed together
        if (!this.state.templatedEdited) {
            this.setState({
                templatedEdited: true
            });
        }
        this.setState({
            templateInputs: templateInputs
        });
    }

    handleMotionChange(entryIndex, val) {
        let selectedMotions = [...this.state.selectedMotions];
        let templateInputs = [...this.state.templateInputs];
        selectedMotions[entryIndex] = val;
        templateInputs[entryIndex] = val ? JSON.parse(JSON.stringify(val.ActionReferences)) : null;
        this.setState({
            selectedMotions: selectedMotions,
            templateInputs: templateInputs,
        }, () => {
            if (val && val.ActionReferences && val.ActionReferences.find(ar => ar.ActionReferenceType === "LegislationText")) {
                this.getTextList();
            }
        });
    }

    getTextList() {
        const entries = this.props.formData.MinutesEntries;
        entries.forEach(entry => {
            if (entry.LegislationID && !this.state.textList.find(text => text.LegislationID === entry.LegislationID)) {
                this.props.actions.getTextList('?legislationID=' + entry.LegislationID + '&sessionID=' + this.props.sessionId)
                    .then(() => {
                        let textList = [...this.props.bills.textList];
                        textList.forEach(text => {
                            text.label = text.DocumentCode;
                            text.value = text.LegislationTextID;
                        });
                        this.setState(state => ({
                            textList: state.textList.concat(textList)
                        }));
                    });
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentActivitySequence !== this.props.currentActivitySequence) {
            this.setState({
                selectedMotions: [],
                newEntries: [],
                templateInputs: [],
                templatedEdited: false
            });
            this.getCatActionList();
        }
    }

    componentDidMount() {
        this.getCatActionList();
        this.getNonLegislativeActionList();
    }

    getCatActionList() {
        //Get the calendar action reference data
        const catId = this.props.formData.CalendarCategoryTypeID;
        const referenceParams = `?calendarCategoryTypeID=${catId}&chamberCode=S`;
        this.props.actions.getReferences(referenceParams)
            .then(() => {
                let motionOptions = this.props.calendar.references;
                motionOptions.forEach(reference => {
                    reference.label = reference.Description;
                    reference.value = reference.CalendarActionID;
                });
                this.setState({
                    motionOptions: motionOptions
                });
            });
    }

    toggleMenu(entryIndex, e) {
        if (e) {
            //Do not hide the small motion form if a child of the form is focused
            if (e.currentTarget.contains(e.relatedTarget)) {
                return;
            }
        }
        this.setState({
            showMenu: entryIndex
        }, () => {
            if (this.menuBoxRef.current) {
                this.menuBoxRef.current.focus();
            }
        });
    }

    addEntry(entryIndex, position) {
        let entries = [...this.props.formData.MinutesEntries];
        const newEntry = {
            IsOOB: entries[entryIndex].IsOOB,
            IsPublic: false,
            MinutesCategoryID: entries[entryIndex].MinutesCategoryID
        }
        entries.splice(entryIndex + position, 0, newEntry);
        entries.forEach((entry, idx) => {
            entry.Sequence = idx + 1
        });
        this.props.handleSaveEntry(entries);
        this.setState({
            showMenu: -1,
            newEntries: [],
            selectedMotions: []
        });
    }

    handleCommentBoxChange(content) {
        this.setState({
            commentBoxContent: content
        });
    }

    toggleCommentBox(show) {
        const entries = this.props.formData.MinutesEntries;
        let commentBoxContent = [];
        if (entries[show] && entries[show].MinutesSummaries) {
            if (entries[show].MinutesSummaries.length !== 0) {
                commentBoxContent = entries[show].MinutesSummaries;
            }
        }
        this.setState({
            showCommentBox: show,
            commentBoxContent: commentBoxContent
        });
    }

    saveComment() {
        let entries = [...this.props.formData.MinutesEntries];
        if (entries[this.state.showCommentBox]) {
            let entry = entries[this.state.showCommentBox];
            entry.MinutesSummaries = this.state.commentBoxContent;
            this.props.handleSaveEntry(entry);
            this.setState({
                showCommentBox: false
            });
        }
    }

    toggleShowDeleted(entryIndex) {
        let entries = [...this.state.newEntries];
        entries[entryIndex].showDeleted = !entries[entryIndex].showDeleted;
        this.setState({
            newEntries: entries
        });
    }

    getNonLegislativeActionList() {
        //Get the non legislative action reference data
        this.props.actions.getBillEventReferences("?isActive=true").then(() => {
            let references = [...this.props.bills.billEventRef].filter(ref => ref.AdministrativeAction);
            references.forEach(ref => {
                ref.label = ref.CalendarDescription
                ref.value = `${ref.EventCode}-${ref.IsPassed}`
            });
            this.setState({
                references: references
            });
        });
    }

    handleNewMotionChange(value) {
        this.setState({
            selectedMotion: value,
            templateInputs: value ? JSON.parse(JSON.stringify(value.ActionReferences)) : [],
            templatedEdited: false
        });
    }

    render() {
        const { selectedMotion, references } = this.state;
        const fontSize = '0.8em';
        const selectStyle = {
            control: styles => ({
                ...styles,
                minHeight: '0px',
                padding: '0.12em 0.6em !important',
            }),
            valueContainer: styles => ({
                ...styles,
                padding: 0,
            }),
            input: styles => ({
                ...styles,
                fontSize: fontSize,
                lineHeight: 'normal',
            }),
            singleValue: styles => ({
                ...styles,
                fontSize: fontSize,
            }),
            placeholder: styles => ({
                ...styles,
                fontSize: fontSize
            }),
            option: styles => ({
                ...styles,
                fontSize: fontSize
            }),
            dropdownIndicator: styles => ({
                ...styles,
                padding: '5px'
            }),
        }
        const showDeletedItemsCheckbox = (entry) => {
            return !!entry.MinutesActivities.find(act => act.DeletionDate)
        }
        // Sometimes the deleted items are shown and other times they are not. If they are not shown then the first activity might not be the first activity on the screen.
        // So this variable is used to figure out whether to show the hr or not that seperates activities.
        const getIndexOfFirstActivity = entry => {
            if (entry.showDeleted) {
                return 0
            } else {
                return entry.MinutesActivities.findIndex(act => !act.DeletionDate);
            }
        }

        return (
            <div className="user-forms">
                <div className="dlas-forms tooltip-wrap">
                    <form>
                        {this.props.formData.CalendarCategoryTypeID == 132 /* Order of Business */ &&
                            <div className="multi-row eight-and-one no-margin" style={{ marginBottom: "20px" }}>
                                <div>
                                    <label>Motion</label>
                                    <Select
                                        value={selectedMotion}
                                        options={references}
                                        onChange={this.handleNewMotionChange}
                                        styles={selectStyle}
                                        isClearable
                                    />
                                </div>
                                <div className="inner-grid two">
                                    <div>
                                        <br />
                                        <button type='button' disabled={!this.state.selectedMotion} className='button' onClick={() => this.insertMotion(-1)}>Add Motion</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                    {this.state.showCommentBox !== false &&
                        <CommentBox
                            toggleBox={this.toggleCommentBox}
                            handleCommentBoxChange={this.handleCommentBoxChange}
                            comments={this.state.commentBoxContent}
                            saveComment={this.saveComment}
                        />
                    }
                    <div className={this.props.disableChanges ? 'dnd-disabled' : ''}>
                        <DragDropContext onDragEnd={this.onEntryDragEnd}>
                            <Droppable droppableId={`entries-droppable`}>
                                {(provided, snapshot) =>
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.props.formData.MinutesEntries.map((entry, entryIndex) =>
                                            <Draggable key={`${entry.MinutesEntryID}-${entry.Sequence}`} draggableId={`${entry.MinutesEntryID}-${entry.Sequence}`} index={entryIndex}>
                                                {(provided, snapshot) =>
                                                    <div  {...provided.dragHandleProps} ref={provided.innerRef} {...provided.draggableProps} className="section-content">
                                                        <div>
                                                            {this.state.newEntries[entryIndex] ?
                                                                <React.Fragment>
                                                                    {showDeletedItemsCheckbox(this.state.newEntries[entryIndex]) &&
                                                                        <div className="flex-row flex-vertical-center flex-start">
                                                                            <div className="toggle-switch" onClick={() => this.toggleShowDeleted(entryIndex)}>
                                                                                <input type="checkbox" checked={this.state.newEntries[entryIndex].showDeleted} />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                            <label style={{ fontSize: "12px" }} className="checkbox-label">Show Deleted</label>
                                                                        </div>}
                                                                    <div className="no-gap-grid two-col-sidebar">
                                                                        <DragDropContext onDragEnd={res => this.onActivityDragEnd(res, entryIndex)}>
                                                                            <Droppable droppableId={`activities-${entryIndex}-droppable`}>
                                                                                {(provided, snapshot) =>
                                                                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                                                        {this.state.newEntries[entryIndex].MinutesActivities && this.state.newEntries[entryIndex].MinutesActivities.map((activity, activityIndex) => {
                                                                                            if (!this.state.newEntries[entryIndex].showDeleted && activity.DeletionDate) {
                                                                                                return null;
                                                                                            } else {
                                                                                                return (<Draggable key={`${activity.MinutesActivityID}-${activity.Sequence}`} draggableId={`${activity.MinutesActivityID}-${activity.Sequence}`} index={activityIndex}>
                                                                                                    {(provided, snapshot) =>
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            {...provided.draggableProps}
                                                                                                            style={getItemStyle(
                                                                                                                snapshot.isDragging,
                                                                                                                provided.draggableProps.style
                                                                                                            )}>
                                                                                                            {getIndexOfFirstActivity(this.state.newEntries[entryIndex]) !== activityIndex && <hr className="faded-line" />}
                                                                                                            <div className="no-gap-grid two-col-sidebar-backwards">
                                                                                                                <div {...provided.dragHandleProps}>
                                                                                                                    <button className="button draggable">Drag and drop</button>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <div className="no-gap-grid two-col-sidebar">
                                                                                                                        <div>
                                                                                                                            <div>
                                                                                                                                <span
                                                                                                                                    style={{ textDecoration: this.state.newEntries[entryIndex].MinutesActivities[activityIndex].DeletionDate ? 'line-through' : 'none' }}
                                                                                                                                    className="small-text">
                                                                                                                                    {this.state.newEntries[entryIndex].MinutesActivities[activityIndex].ActivityReferences.map(tInput => {
                                                                                                                                        if (!tInput.ReferenceText && tInput.ActionReferenceType !== "Text") {
                                                                                                                                            return `[${tInput.ActionReferenceType}]`
                                                                                                                                        }
                                                                                                                                        return tInput.ReferenceText
                                                                                                                                    }).join('') + (this.state.newEntries[entryIndex].MinutesActivities[activityIndex].VoteTally ? ' ' + this.state.newEntries[entryIndex].MinutesActivities[activityIndex].VoteTally : '')}
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                {!this.state.newEntries[entryIndex].MinutesActivities[activityIndex].DeletionDate &&
                                                                                                                                    <InputTypes
                                                                                                                                        committeeList={this.props.committeeList}
                                                                                                                                        communicationList={this.props.communicationList}
                                                                                                                                        memberList={this.props.memberList}
                                                                                                                                        actionTypes={this.props.actionTypes}
                                                                                                                                        handleChange={this.handleChange}
                                                                                                                                        entryIndex={entryIndex}
                                                                                                                                        activityIndex={activityIndex}
                                                                                                                                        references={this.state.newEntries[entryIndex].MinutesActivities[activityIndex].ActivityReferences || []}
                                                                                                                                        textList={this.state.textList.filter(text => this.state.newEntries[entryIndex].LegislationID === text.LegislationID)}
                                                                                                                                    />
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            {!this.state.newEntries[entryIndex].MinutesActivities[activityIndex].DeletionDate ?
                                                                                                                                <button onClick={() => this.removeActivity(entryIndex, activityIndex)} type="button" className="button remove"></button>
                                                                                                                                :
                                                                                                                                <button onClick={() => this.addBackActivity(entryIndex, activityIndex)} type="button" className="button add"></button>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                </Draggable>)
                                                                                            }
                                                                                        })}
                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                }
                                                                            </Droppable>
                                                                        </DragDropContext>
                                                                        <div style={{ position: "relative" }}>
                                                                            <div className="inline-list bill-grid-actions" key={entry.MinutesEntryID}>
                                                                                <button onClick={() => this.saveChange(entryIndex)} type="button" className="icon save"></button>
                                                                                <button onClick={() => this.toggleEdit(entryIndex)} type="button" className="icon delete"></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <div className="flex-row flex-vertical-center">
                                                                    <div className="flex-row flex-vertical-center inline-list">
                                                                        <div>
                                                                            <span className="icon menu" onClick={(e) => this.toggleMenu(entryIndex)}></span>
                                                                            {this.state.showMenu === entryIndex &&
                                                                                <div style={{ marginLeft: '-25px', width: 'auto' }} className="info-box blue" ref={this.menuBoxRef} onBlur={(e) => this.toggleMenu(-1, e)} contentEditable suppressContentEditableWarning={true}>
                                                                                    <div contentEditable={false}>
                                                                                        <div>
                                                                                            <button type="button" onClick={() => this.addEntry(entryIndex, 0)} className="button-link">Add Entry Above</button>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button type="button" onClick={() => this.addEntry(entryIndex, 1)} className="button-link">Add Entry Below</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {!entry.MinutesActivities || entry.MinutesActivities.length === 0 &&
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        {entry.EntryText ? entry.EntryText : (
                                                                                            this.props.formData.CategoryType === "Chairmans Report" && (
                                                                                                entry.MinutesSummaries && entry.MinutesSummaries.find(ms => ms.CommitteeName) ?
                                                                                                    <>From {entry.MinutesSummaries.find(ms => ms.CommitteeName).CommitteeName}: <em>No chair's reports included</em></>
                                                                                                    :
                                                                                                    <em>No chair's reports included</em>
                                                                                            )
                                                                                        )
                                                                                        }
                                                                                    </span>
                                                                                    {this.props.formData.CategoryType === "Chairmans Report" && <React.Fragment><i data-tip={`You may choose to include or exclude one or more chair's reports from this committee using the checkboxes and then\nregenerating the summary using the button that will appear at the top of the category upon interacting with a checkbox.`}
                                                                                        className={`icon notification`} style={{ marginLeft: '5px' }} />
                                                                                        <ReactTooltip key={new Date()} />
                                                                                    </React.Fragment>}
                                                                                </React.Fragment>
                                                                            }
                                                                            {entry.MinutesActivities && entry.MinutesActivities.filter(a => !a.DeletionDate).map((activity, activityIndex) =>
                                                                                <span key={activityIndex}>
                                                                                    {activityIndex !== 0 && ', '}{activity.Description}{activity.VoteTally ? ' ' + activity.VoteTally : null}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="flex-row flex-vertical-center inline-list">
                                                                            <button disabled={this.props.disableChanges} onClick={() => this.toggleCommentBox(entryIndex)} type="button" className="button-link">{entry.MinutesSummaries && entry.MinutesSummaries.length > 0 ? "Edit" : "Add"} Comment</button>
                                                                            {(entry.EntryText || (entry.MinutesActivities && entry.MinutesActivities.length !== 0)) &&
                                                                                <React.Fragment>
                                                                                    {!this.state.editing &&
                                                                                        <button disabled={this.props.disableChanges} onClick={() => this.toggleEdit(entryIndex)} type="button" className="button-link">Edit Motions</button>
                                                                                    }
                                                                                    <button disabled={this.props.disableChanges} type="button" onClick={() => this.props.toggleMoveEntry(true, this.props.currentActivitySequence - 1, entryIndex)} className="button">Move</button>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <hr className="faded-line" />
                                                        {/* Hide motion form unless it's reading of the journal if the category is order of business, or if its a chairmans report category */}
                                                        {(this.props.formData.CalendarCategoryTypeID !== 132 || entry.MinutesActivities.find(x => x.Description === "Reading of the Journal")) && this.props.formData.CategoryType !== 'Chairmans Report' &&
                                                            <div className="multi-row one-small-small no-margin">
                                                                <div>
                                                                    <label>{entry.LegislationNumber ? entry.LegislationNumber + " Motion" : "Motion"}</label>
                                                                    <Select
                                                                        options={this.props.formData.CalendarCategoryTypeID !== 132 ? this.state.motionOptions : this.state.motionOptions.filter(x => x.CalendarActionID === 2011)}
                                                                        value={this.state.selectedMotions[entryIndex]}
                                                                        onChange={val => this.handleMotionChange(entryIndex, val)}
                                                                        styles={selectStyle}
                                                                        isClearable
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <br />
                                                                    <button type='button' disabled={!this.state.selectedMotions[entryIndex]} className='button' onClick={() => this.insertMotion(entryIndex)}>Add Motion</button>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.templateInputs[entryIndex] && this.state.templateInputs[entryIndex].length > 0 &&
                                                            <div>
                                                                <br />
                                                                <div>
                                                                    <div>
                                                                        <span className='txt-greyed'>
                                                                            {this.state.templateInputs[entryIndex].map((tInput, tIndex) =>
                                                                                <React.Fragment key={tIndex}>
                                                                                    {tInput.ReferenceText ? tInput.ReferenceText : `[${tInput.ActionReferenceType}]`}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <InputTypes
                                                                        memberList={this.props.memberList}
                                                                        committeeList={this.props.committeeList}
                                                                        communicationList={this.props.communicationList}
                                                                        handleChange={this.handleTemplateChange}
                                                                        entryIndex={entryIndex}
                                                                        activityIndex={0}
                                                                        references={this.state.templateInputs[entryIndex] || []}
                                                                        textList={this.state.textList.filter(text => entry.LegislationID === text.LegislationID ? true : false)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.props.formData.CategoryType !== 'Chairmans Report' && <br />}
                                                        {this.state.newEntries[entryIndex] ?
                                                            this.state.newEntries[entryIndex].MinutesSummaries && this.state.newEntries[entryIndex].MinutesSummaries.filter(ms => !ms.DeletionDate).map((summary, summaryIndex) =>
                                                                <div style={{ padding: '5px 0px', fontSize: '0.8em' }}>
                                                                    {renderHTML(summary.MinutesSummary)}
                                                                </div>
                                                            )
                                                            : entry.MinutesSummaries && entry.MinutesSummaries.filter(ms => !ms.DeletionDate).map((summary, summaryIndex) =>
                                                                <React.Fragment key={summaryIndex}>
                                                                    {this.props.formData.CategoryType === 'Chairmans Report' ?
                                                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                                                            <div className="toggle-switch" onClick={() => this.props.toggleChairsReport(this.props.currentActivitySequence - 1, entryIndex, summaryIndex)}>
                                                                                <input type="checkbox" checked={summary.IsActive} />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                            <label className="checkbox-label no-background">{summary.MinutesDate && moment(summary.MinutesDate).format("M/D/yyyy") + (summary.MinutesNumber ? ' (' + summary.MinutesNumber + ')' : '') + ':'} {renderHTML(summary.MinutesSummary)}</label>
                                                                        </div>
                                                                        :
                                                                        <div style={{ padding: '5px 0px', fontSize: '0.8em' }}>
                                                                            {renderHTML(summary.MinutesSummary)}
                                                                        </div>
                                                                    }
                                                                    <hr className="faded-line" />
                                                                </React.Fragment>
                                                            )}
                                                    </div>}
                                            </Draggable>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                }
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div >
        );
    }
}

export default connect(
    (state) => {
        const { calendar, minutes, bills } = state;
        return {
            calendar,
            minutes,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, calendarActionCreators, minutesActionCreators, billActionCreators), dispatch)
        }
    },
    null,
    {
        forwardRef: true
    }
)(NonLegislativeForm)